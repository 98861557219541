var dropdownOpeningEvent;
var dropdownClosingEvent;
if (typeof Event === "function") {
  dropdownOpeningEvent = new Event("dropdown-opening");
  dropdownClosingEvent = new Event("dropdown-closing");
} else {
  dropdownOpeningEvent = document.createEvent("Event");
  dropdownOpeningEvent.initEvent("dropdown-opening", false, false);
  dropdownClosingEvent = document.createEvent("Event");
  dropdownClosingEvent.initEvent("dropdown-closing", false, false);
}
var init = function init() {
  [].slice.call(document.querySelectorAll(".dropdown")).map(function(el) {
    var button = el.querySelector(".button");
    button.addEventListener("click", function(e) {
      if (el.classList.contains("is-active")) {
        el.dispatchEvent(dropdownClosingEvent);
        el.classList.remove("is-active");
      } else {
        el.dispatchEvent(dropdownOpeningEvent);
        el.classList.add("is-active");
      }
    });
  });

  document.addEventListener(
    "click",
    function(e) {
      e.stopPropagation();

      [].slice.call(document.querySelectorAll(".dropdown")).map(function(el) {
        if (!el.contains(e.target)) {
          el.classList.remove("is-active");
          el.dispatchEvent(dropdownClosingEvent);
        }
      });
    },
    false
  );
};
window.addEventListener("load", init, false);
